var tableColumns = [{
  title: "交易ID",
  dataIndex: "manuscriptid",
  key: "manuscriptid",
  width: 140
}, {
  title: "用户昵称",
  dataIndex: "creatorname",
  key: "creatorname",
  width: 120,
  scopedSlots: {
    customRender: "creatorname"
  }
}, {
  title: "交易金额",
  dataIndex: "channel",
  key: "channel",
  width: 100,
  scopedSlots: {
    customRender: "channel"
  }
}, {
  title: "分佣百分比",
  dataIndex: "channel",
  key: "channel",
  width: 100,
  scopedSlots: {
    customRender: "channel"
  }
}, {
  title: "流水金额",
  dataIndex: "channel",
  key: "channel",
  width: 100,
  scopedSlots: {
    customRender: "channel"
  }
}, {
  title: "流水类型",
  dataIndex: "advertisername",
  key: "advertisername",
  width: 180,
  scopedSlots: {
    customRender: "advertisername"
  }
}, {
  title: "HOOD ID",
  dataIndex: "taskname",
  key: "taskname",
  width: 100,
  scopedSlots: {
    customRender: "taskname"
  }
}, {
  title: "交易类型",
  dataIndex: "transactionType",
  key: "transactionType",
  width: 180,
  scopedSlots: {
    customRender: "transactionType"
  }
}, {
  title: "交易hash",
  dataIndex: "status",
  key: "status",
  width: 80,
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "流水时间",
  dataIndex: "submittime",
  key: "submittime",
  width: 180,
  scopedSlots: {
    customRender: "submittime"
  }
}];
export { tableColumns };